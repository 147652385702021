import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

/** Http Service Layer */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  /** generate url for backend call */
  generateFullUrl(url): string {
    return environment.apiUrl + url;
  }

  /** generate url query params for backend call */
  generateUrlQueryParams(urlParams: Record<string, string> = null): HttpParams {
    let params = new HttpParams();

    if (urlParams) {
      Object.keys(urlParams).forEach((property: string) => {
        if (property in urlParams) {
          params = params.append(property, urlParams[property]);
        }
      });
    }

    return params;
  }

  get(url, urlParams: Record<string, string> = null): Observable<any> {
    return this.http
      .get(this.generateFullUrl(url), {
        params: this.generateUrlQueryParams(urlParams),
      })
      .pipe(first());
  }

  post(url, data): Observable<any> {
    return this.http.post(this.generateFullUrl(url), data).pipe(first());
  }

  put(url, data): Observable<any> {
    return this.http.put(this.generateFullUrl(url), data).pipe(first());
  }

  patch(url, data): Observable<any> {
    return this.http.patch(this.generateFullUrl(url), data).pipe(first());
  }

  delete(url): Observable<any> {
    return this.http.delete(this.generateFullUrl(url)).pipe(first());
  }
}
