import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { AuthService } from '../auth/auth.service';
import { LoginData } from './classes/LoginData';
import { LoginResponse } from './classes/LoginResponse';

@Injectable({
  providedIn: 'root',
})
export class AuthFormsService {
  constructor(private httpService: HttpService, private auth: AuthService) {}

  login(loginData: LoginData): Observable<LoginResponse> {
    return this.httpService.post('user/login/web', loginData);
  }
}
