import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form/login-form.component';
import { SharedModule } from '../../shared.module';
import { FormComponentsModule } from '../form-components/form-components.module';

@NgModule({
  declarations: [LoginFormComponent],
  imports: [CommonModule, SharedModule, FormComponentsModule],
  exports: [LoginFormComponent],
})
export class AuthFormsModule {}
