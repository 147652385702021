import { RouterModule, Routes } from '@angular/router';

/*eslint-disable*/
const routes: Routes = [
  {
    path: 'auth-user',
    loadChildren: () =>
      import('./pages/auth-user/auth-user.module').then((mod) => mod.AuthUserModule),
  },
  {
    path: 'articles',
    loadChildren: () =>
      import('./pages/articles/articles.module').then((mod) => mod.ArticlesModule),
  },
  {
    path: 'register-patient-user',
    loadChildren: () =>
      import('./pages/register-patient-user/register-patient-user.module').then(
        (mod) => mod.RegisterPatientUserModule
      ),
  },
  { path: '**', redirectTo: 'auth-user' },
];

/** Main routing module */
export const AppRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
