import { AbstractControl, Validators } from '@angular/forms';

export class FormCustomValidators {
  static isSameValidator(compareField) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.parent || !control) {
        return null;
      }
      return control.value === control.parent.get(compareField).value
        ? null
        : { isSame: control.value };
    };
  }

  static emailValidator() {
    return Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,63}$');
  }

  // Adószám: Pontosan 11 számjegyből álló számsor az alábbi maszk formátummal: xxxxxxxx-y-zz
  static taxNumberValidator() {
    return Validators.pattern('^[0-9]{8}-[0-9]-[0-9]{2}$|^[0-9]{11}$');
  }

  // Csak kis és nagybetűket, valamint pont karaktert fogad fogad el
  static nameValidator() {
    return Validators.pattern('^[A-Za-záéiíóöőúüűÁEÉÍÓÖŐÚÜŰ0-9 ./-]{0,50}$');
  }

  static userNameValidator() {
    return Validators.pattern('^[0-9a-zA-Z.@]{6,30}$');
  }

  static numberValidator() {
    return Validators.pattern('^[0-9]*$');
  }

  // minden országra rendszám vizsgálat
  static plateNumberValidator() {
    return Validators.pattern('^[a-zA-Z0-9- ]+$');
  }

  static phoneNumberValidator() {
    return Validators.pattern('^[(]{0,1}[+]*[0-9]{1,2}[)]{0,1}\\s[0-9]{1,2}\\s[0-9]{6,7}$');
  }

  static numberPositiveValidator() {
    return Validators.pattern('^[[1-9]{1}[0-9]*$');
  }

  // 1 nagybetű, egy kisbetű, egy szám, nem lehet spéci karakter, min 8 karakter
  static passwordValidator() {
    return Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{8,30}$');
  }
}
