import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SideMenuService } from '../side-menu/side-menu.service';
import { BaseUser } from '../../../classes/user/BaseUser';
import { AuthService } from '../auth/auth.service';
import { BaseComponent } from '../base-component/base.component';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() slideHeader: boolean;
  @Input() hasStickySideBar: boolean;

  loggedInUser: BaseUser;
  lowResControl = new FormControl();

  constructor(
    public sideMenuService: SideMenuService,
    private authService: AuthService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.initLoggedInUser();

    return super.ngOnInit();
  }

  logout(): void {
    this.authService.logout();
    this.sideMenuService.toggleSideMenu(false);
    this.router.navigate(['/auth-user']);
  }

  private initLoggedInUser(): void {
    this.authService.loggedInUserChange.pipe(untilDestroyed(this)).subscribe((user) => {
      this.loggedInUser = user;
    });
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
}
