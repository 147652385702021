import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { ArticleModalComponent } from './modals/article-modal/article-modal.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { MatModalBaseComponent } from './mat-modal-base.component';
import { MatModalService } from './mat-modal.service';
import { FormComponentsModule } from '../form-components/form-components.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { DoctorVisitChooserModule } from '../doctor-visit-chooser/doctor-visit-chooser.module';

@NgModule({
  declarations: [
    MatModalBaseComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalContentComponent,
    ArticleModalComponent,
    ConfirmModalComponent,
  ],
  exports: [
    MatModalBaseComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalContentComponent,
  ],
  providers: [MatModalService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    FormComponentsModule,
    FileUploadModule,
    DoctorVisitChooserModule,
  ],
  entryComponents: [ArticleModalComponent, ConfirmModalComponent],
})
export class MatModalModule {}
