import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  private sideMenuSubject: Subject<boolean>;

  public getSideMenuSubject(): Observable<boolean> {
    if (!this.sideMenuSubject) {
      this.sideMenuSubject = new Subject<boolean>();
    }
    return this.sideMenuSubject.asObservable();
  }

  public toggleSideMenu(isOpen?: boolean) {
    this.sideMenuSubject.next(isOpen);
  }
}
