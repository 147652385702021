import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInOutTransition = (delay: string, enterDelay = '') => {
  return trigger('fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(`${delay}  ${enterDelay} ease-in`, style({ opacity: 1 })),
    ]),
    transition(':leave', [animate(`${delay} ease-out`, style({ opacity: 0 }))]),
  ]);
};
