import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileUploadComponent } from './file-upload.component';
import { DndDirective } from './dnd.directive';

@NgModule({
  declarations: [FileUploadComponent, DndDirective],
  imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
