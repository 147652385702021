import { UserRole } from '../../../../classes/user/UserRole';
import { UserPermission } from '../../../../classes/user/UserPermission';
import { UserRolePermission } from '../../../../classes/user/UserRolePermission';

export class AuthUtils {
  static isContainUserRole(routeEnabledDisabledRoles: UserRole[], userRole: UserRole): boolean {
    return (routeEnabledDisabledRoles || []).some((role) => role === userRole);
  }

  static isContainUserPermission(
    routeEnabledDisabledPermissions: UserPermission[],
    userRole: UserRole
  ): boolean {
    return (routeEnabledDisabledPermissions || []).some(
      (permission) => UserRolePermission[userRole].indexOf(permission) !== -1
    );
  }
}
