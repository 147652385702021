import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SideMenuService } from './side-menu.service';
import { SideMenuMode } from './classes/SideMenuMode';
import { SideMenuToggleEvent } from './classes/SideMenuToggleEvent';
import { SideMenuItems } from './classes/SideMenuItems';
import { fadeInOutTransition } from '../../../animations';
import { BaseComponent } from '../base-component/base.component';

@UntilDestroy()
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [fadeInOutTransition('0.3s')],
})
export class SideMenuComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  isFixedBelowHeader = true;
  @Input()
  isFullWidth = false;
  @Input()
  isBackDropEnabled = true;
  @Input()
  slideMode = SideMenuMode.Overlay;
  @Input()
  menuItems: SideMenuItems[];
  @Input()
  closeMenuAfterItemClick = true;
  @Input()
  hasStickySideBar = false;
  @Output()
  toggleMenu: EventEmitter<SideMenuToggleEvent> = new EventEmitter<SideMenuToggleEvent>();

  public isMenuOpen: boolean;

  constructor(public sideMenuService: SideMenuService, private router: Router) {
    super();
  }

  /**
   * Azért van szükség a return-re, mivel az ős osztályban definiáltuk,
   * hogy kötelező visszatérnie egy adott értékkel. Ezt úgy tudod könnyel elérni,
   * ha meghívod az ős lifecycle hook-ját. Ezzel kényszerítjük ki, hogy az ős
   * lifecycle hook-ja mindig meg legyen hívva. Ellenkező esetben hibát fog dobni.
   */
  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.handleSideMenuToggle();
    return superCalled;
  }

  public onClick(item) {
    if (!item.url) {
      item.onClick();
    } else {
      this.router.navigate([item.url]);
    }

    if (this.closeMenuAfterItemClick) {
      this.sideMenuService.toggleSideMenu(false);
    }
  }

  public getItemIsActive(item): boolean {
    return this.router.url.includes(item.url);
  }

  private handleSideMenuToggle() {
    this.sideMenuService
      .getSideMenuSubject()
      .pipe(untilDestroyed(this))
      .subscribe((isOpen) => {
        this.isMenuOpen = isOpen === true || isOpen === false ? isOpen : !this.isMenuOpen;
        if (this.slideMode === SideMenuMode.Push) {
          this.toggleMenu.emit({
            header: this.isFixedBelowHeader ? false : this.isMenuOpen,
            content: this.isMenuOpen,
          });
          if (!this.isBackDropEnabled) {
            console.warn(
              '[SIDE MENU] PUSH mode without backdrop - some elements might go offscreen when menu is open'
            );
          }
        }
      });
  }
}
