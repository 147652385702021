import { map, tap } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { GlobalLoaderService } from '../global-loader/services/global-loader.service';
import { version } from '../../../../../package.json';
import { AppConfig } from '../../../config/app.config';
import { environment } from '../../../../environments/environment';
import { SnackbarService } from '../../services/snackbar.service';

@Injectable()
export class WsInterceptor implements HttpInterceptor {
  private requests = {};

  constructor(
    private loader: GlobalLoaderService,
    private injector: Injector,
    private translate: TranslateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestClone = req.clone();
    const apiCall = requestClone.urlWithParams.includes(environment.apiUrl);
    if (apiCall) {
      requestClone = this.setCommonHeaders(requestClone);
      this.showLoader(requestClone);
    }

    return next.handle(requestClone).pipe(
      tap(
        () => {},
        (err) => {
          if (err instanceof HttpErrorResponse) {
            this.handleError(err);
          }

          if (apiCall) {
            this.hideLoader(requestClone);
          }
        }
      ),
      map((event) => {
        if (event instanceof HttpResponse) {
          if (apiCall) {
            this.hideLoader(requestClone);
          }
        }
        return event;
      })
    );
  }

  setCommonHeaders(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        'Accept-Language': this.translate.currentLang || AppConfig.DEFAULT_LANGUAGE,
        'x-client-version': version,
        'x-client-env': environment.name,
        'x-request-id': uuidv4(),
      },
      withCredentials: true,
    });
  }

  private hideLoader(requestClone) {
    if (this.requests[requestClone] && this.requests[requestClone] > 0) {
      this.requests[requestClone]--;
      this.loader.hide();
    }
  }

  private showLoader(requestClone) {
    if (!this.requests[requestClone]) {
      this.requests[requestClone] = 0;
    }

    this.requests[requestClone]++;
    this.loader.show();
  }

  private handleError(err) {
    const snackBar = this.injector.get<SnackbarService>(SnackbarService);
    snackBar.showError(this.getErrorDetail(err));

    if (err.status === 401) {
      const auth = this.injector.get<AuthService>(AuthService);
      auth.logout();
    }
  }

  private getErrorDetail(error): string {
    const errors = error.error.errors;
    const errorDetails = [];

    if (Array.isArray(errors)) {
      for (let i = 0; i < errors.length; i++) {
        if (errors[i]['detail']) {
          errorDetails.push(errors[i]['detail']);
        }
      }
    }

    if (errorDetails.length === 0) {
      errorDetails.push(this.translate.instant('Response.Unknown'));
    }

    return errorDetails.join('<br>');
  }
}
