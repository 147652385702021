import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthUserModule } from './pages/auth-user/auth-user.module';
import { SharedModule } from './shared/shared.module';
import { HttpModule } from './shared/modules/http/http.module';
import { GlobalLoaderModule } from './shared/modules/global-loader/global-loader.module';
import { HeaderModule } from './shared/modules/header/header.module';
import { MatModalModule } from './shared/modules/mat-modal/mat-modal.module';
import { HttpMultiLoaderFactory } from './shared/utils/http-multi-loader.util';
import { VersionDisplayModule } from './shared/modules/version-display/version-display.module';
import { AppInitializer } from './app.initializer';
import { SideMenuModule } from './shared/modules/side-menu/side-menu.module';
import { AppInjector } from './app.injector.service';
import { BaseComponentModule } from './shared/modules/base-component/base-component.module';

export const initApp = (initializer: AppInitializer) => {
  return () => initializer.load();
};

/** Main module, imports app wide modules, providers, bootstrap */
@NgModule({
  declarations: [AppComponent],
  imports: [
    HeaderModule,
    SideMenuModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthUserModule,
    AppRoutingModule,
    SharedModule,
    HttpModule,
    VersionDisplayModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpMultiLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GlobalLoaderModule,
    MatModalModule,
    MatSnackBarModule,
    BaseComponentModule,
  ],
  providers: [
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppInitializer],
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'right',
        verticalPosition: 'top',
      },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'hu-HU',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
