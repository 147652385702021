import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { BaseUser } from '../../../classes/user/BaseUser';
import { UserRole, UserRoleObject } from '../../../classes/user/UserRole';
import { AppConfig } from '../../../config/app.config';
import { JwtToken } from '../../../classes/JwtToken';
import { AuthGuardParams } from './classes/AuthGuardParams';

/** Write/read data of user's to/from local storage, */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedInUser: BaseUser;
  loggedInUserChange: BehaviorSubject<BaseUser> = new BehaviorSubject<BaseUser>(null);

  authToken: string;
  previousURL: string;
  jwtHelper = new JwtHelperService();

  constructor(private httpService: HttpService, private injector: Injector) {}

  setUserAndToken(token, userData) {
    this.setToken(token);
    this.setUser(userData);
  }

  redirectToPreviousUrlOrHome() {
    // need to inject router here because if you put it in the constructor, the app initializer will failed to load
    const router = this.injector.get<Router>(Router);

    if (this.previousURL) {
      const splitUrl = this.previousURL.split('?');
      const route = splitUrl[0];
      const { queryParams } = router.parseUrl(this.previousURL);
      router.navigate([route], { queryParams });
      this.previousURL = null;
    } else {
      router.navigate([AppConfig.HOME_URL]);
    }
  }

  logout() {
    const router = this.injector.get<Router>(Router);

    this.setUser(null);
    this.setToken(null);
    router.navigate([AppConfig.LOGIN_URL]);
  }

  loadUser(): Observable<BaseUser> {
    return this.httpService.get('user').pipe(
      map((response) => {
        return response?.data;
      })
    );
  }

  checkAuth(): Promise<boolean> {
    const pureToken = this.getAuthToken();

    return new Promise((resolve) => {
      const jwtToken = this.checkJWTAuthToken(pureToken);

      if (jwtToken) {
        this.setToken(pureToken);

        this.loadUser().subscribe(
          (userData: BaseUser) => {
            this.setUser(userData);
            resolve(true);
          },
          () => {
            this.logout();
            resolve(true);
          }
        );
      } else {
        this.logout();
        resolve(true);
      }
    });
  }

  redirectToHomeByRole(authGuardParams: AuthGuardParams, previousUrl: string) {
    let route;
    const router = this.injector.get<Router>(Router);
    const userRole = this.getUserRole();

    if (authGuardParams.redirectTo) {
      route = authGuardParams.redirectTo;
    } else if (userRole !== UserRole.Unauthorized) {
      route = AppConfig.HOME_URL;

      // extend navigate logic by role
      // if (userRole === UserRole.ADMIN) {
      //   route = '/admin-home';
      // }
    } else {
      route = AppConfig.LOGIN_URL;
    }

    this.previousURL = previousUrl;
    router.navigate([route]);
  }

  getLoggedInUser(): BaseUser {
    return this.loggedInUser;
  }

  isLoggedIn(): boolean {
    return !!this.loggedInUser;
  }

  getAuthToken(): string {
    return localStorage.getItem(AppConfig.TOKEN_STORAGE_KEY);
  }

  getUserRole(): UserRole {
    let role = UserRole.Unauthorized;

    if (this.loggedInUser) {
      role = this.loggedInUser.role;
    }

    return role;
  }

  setToken(token: string | null) {
    this.authToken = token;
    if (token !== null) {
      localStorage.setItem(AppConfig.TOKEN_STORAGE_KEY, token);
    } else {
      localStorage.removeItem(AppConfig.TOKEN_STORAGE_KEY);
    }
  }

  setUser(userData: BaseUser | null) {
    if (userData) {
      const role = this.mapUserRolesToCurrentRole(userData.roles);
      this.loggedInUser = new BaseUser(userData.id, userData.email, role, userData.roles);
    } else {
      this.loggedInUser = null;
    }
    this.loggedInUserChange.next(this.loggedInUser);
  }

  mapUserRolesToCurrentRole(roles: UserRoleObject[]) {
    let hasAdmin = false;
    let hasWebAdmin = false;
    let hasWebManager = false;

    for (let i = 0; i < roles.length; i++) {
      if (roles[i].roleSlug === UserRole.Admin) {
        hasAdmin = true;
      } else if (roles[i].roleSlug === UserRole.WebAdmin) {
        hasWebAdmin = true;
      } else if (roles[i].roleSlug === UserRole.WebManager) {
        hasWebManager = true;
      }
    }

    let role = UserRole.Unauthorized;
    if (hasAdmin || hasWebAdmin) {
      role = UserRole.WebAdmin;
    } else if (hasWebManager) {
      role = UserRole.WebManager;
    }

    return role;
  }

  private checkJWTAuthToken(token): JwtToken | null {
    const jwtToken = this.getDecodedToken(token);
    if (!jwtToken || !jwtToken.sub || !jwtToken.exp) {
      return null;
    }
    if (this.jwtHelper.isTokenExpired(token, 60 * 30)) {
      console.log('TODO: JWT TOKEN EXPIRED');
      return null;
    }

    return jwtToken;
  }

  private getDecodedToken(token: string): JwtToken {
    try {
      return this.jwtHelper.decodeToken(token);
    } catch (error) {
      console.log('error while parsing token: ', error);
      return null;
    }
  }
}
