import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from '../../shared/modules/base-component/base.component';

@UntilDestroy()
@Component({
  selector: 'app-auth-user',
  templateUrl: './auth-user.component.html',
  styleUrls: ['./auth-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthUserComponent extends BaseComponent {
  constructor(private router: Router) {
    super();
  }

  navigate(path: string): void {
    this.router.navigate([path]);
  }
}
