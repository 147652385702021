import { SideMenuMode } from './SideMenuMode';

export class SideMenuOptions {
  constructor(
    // PUSH kitolja a main contentet, OVERLAY fölé csúszik a main contentnek
    public slideMode = SideMenuMode.Push,
    // van-e alapból vékony mindig látható menü
    public hasStickySideBar = true,
    // menüpontra kattintás után bezárja-e automatikusan a menüt
    public closeMenuAfterItemClick = true,
    // képernyő teljes szélességét kitölti a nyitott menü (pl: mobilon hasznos lehet)
    public isFullWidth = false,
    // true = app header alatt jelenik meg a menü, false = app header-t is tolja/fölés csúszik
    public isFixedBelowHeader = true,
    // ha kinyitod a menüt legyen-e a main content fölött egy backdrop
    public isBackDropEnabled = false
  ) {}
}
