import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../../shared/modules/auth/auth.guard';
import { UserRole } from '../../classes/user/UserRole';
import { AuthUserComponent } from './auth-user.component';

const routes: Routes = [
  {
    path: '',
    component: AuthUserComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'AuthForms.PageTitle',
      authGuardParams: {
        rolesEnabled: [UserRole.Unauthorized],
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthUserRoutingModule {}
