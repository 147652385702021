import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../modules/http/http.service';
import { Doctor } from '../../classes/master/Doctor';
import { VisitClass } from '../../classes/master/VisitClass';
import { VisitType } from '../../classes/master/VisitType';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  doctors: Doctor[];
  visitClasses: VisitClass[];

  constructor(private httpService: HttpService) {}

  getDoctors(): Observable<Doctor[]> {
    if (this.doctors) {
      return of(this.doctors.filter(() => true));
    }

    return this.initDoctors();
  }

  getVisitClasses(): Observable<VisitClass[]> {
    if (this.visitClasses) {
      return of(this.visitClasses.filter(() => true));
    }

    return this.initVisitClasses();
  }

  getVisitTypes(visitClassId: number, doctorId: number): Observable<VisitType[]> {
    let url = `visit-type/0/${visitClassId}`;
    if (doctorId) {
      url += `/${doctorId}`;
    }

    return this.httpService.get(url).pipe(
      map((response) => {
        return response.visitTypes;
      })
    );
  }

  initAll() {
    this.initDoctors().subscribe();
    this.initVisitClasses().subscribe();
  }

  private initDoctors(): Observable<Doctor[]> {
    return this.httpService.get(`doctors`).pipe(
      map((response) => {
        this.doctors = response.doctors;
        return this.doctors;
      })
    );
  }

  private initVisitClasses(): Observable<VisitClass[]> {
    return this.httpService.get(`visit-class/0`).pipe(
      map((response) => {
        this.visitClasses = response.visitClasses;
        return this.visitClasses;
      })
    );
  }
}
