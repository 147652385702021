<div [class.slide-content]='slideHeader'
     [class.has-sticky-side-bar]='hasStickySideBar'
     [class.mobile]='isMobile'
     class='app-header d-flex'>

    <div class='d-flex w-100 align-items-center justify-content-between ml-2 mr-2'>
        <div class='header-main-logo'>
            <img alt='rmc-logo' src='/assets/image/header/logo.svg' />
        </div>
        <div>
            <div *ngIf='loggedInUser' class='d-flex align-items-center ml-2'>
                <div class='header-user-name'>
                    {{ loggedInUser.email }}
                </div>
                <div class='header-logout-icon icon-clickable'>
                    <img (click)='logout()'
                         [matTooltip]='"Common.Logout" | translate'
                         [matTooltipShowDelay]='500'
                         alt='rmc-logout'
                         src='/assets/image/header/logout.svg' />
                </div>
            </div>
        </div>
    </div>

</div>
