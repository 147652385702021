import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConstants } from '../../../../config/app.constant';

export interface LoaderState {
  show: number;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderService {
  loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  showCount = 0;

  show() {
    setTimeout(() => {
      this.showCount++;
      this.emitShow();
    });
  }

  hide() {
    setTimeout(() => {
      if (this.showCount > 0) {
        this.showCount--;
        this.emitShow();
      } else {
        console.warn('unnecessary loader hide! showCount was already 0');
      }
    }, AppConstants.LOADER_TIMEOUT);
  }

  emitShow() {
    this.loaderSubject.next({ show: this.showCount } as LoaderState);
  }
}
