import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalLoaderComponent } from './global-loader.component';
import { LoaderSpinnerComponent } from './components/loader-spinner/loader-spinner.component';

@NgModule({
  declarations: [GlobalLoaderComponent, LoaderSpinnerComponent],
  imports: [CommonModule],
  providers: [],
  exports: [GlobalLoaderComponent, LoaderSpinnerComponent],
})
export class GlobalLoaderModule {}
