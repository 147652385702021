<div [class.fixed-below-header]='isFixedBelowHeader'
     [class.full-width]='isFullWidth'
     [class.open]='isMenuOpen'
     [class.sticky-bar]='hasStickySideBar'
     [class.mobile]='isMobile'
     class='sidenav'>
    <div class='side-menu-content'>
        <div *ngIf='hasStickySideBar' class='hamburger-icon'>
            <a (click)='sideMenuService.toggleSideMenu(); false'
               class='side-menu-item menu-item-icon has-sticky-bar mt-0' href=''>
                <div>
                    <img [alt]="'icon'"
                         [src]="'assets/image/menu/hamburger.svg'" />
                </div>
            </a>
            <div class='w-100'></div>
        </div>
        <div *ngFor='let group of menuItems' class='side-menu-group'>
            <div class='side-menu-group-delimiter'></div>
            <div class='d-flex side-menu-items-container'>
                <div class='has-sticky-bar'>
                    <a *ngFor='let item of group.items' [class.has-sticky-bar]='hasStickySideBar && !isMenuOpen'
                       [matTooltip]='item.title | translate'
                       [matTooltipShowDelay]='500'
                       (click)='onClick(item); false'
                       [class.active]='getItemIsActive(item)'
                       class='side-menu-item menu-item-icon' href=''>
                        <div>
                            <img *ngIf='item.icon' [alt]="'icon'"
                                 [src]="'assets/image/' + item.icon"
                                 class='side-menu-item-icon' />
                        </div>
                    </a>
                </div>
                <div class='w-100'>
                    <a (click)='onClick(item); false'
                       *ngFor='let item of group.items'
                       [class.active]='getItemIsActive(item)'
                       class='side-menu-item menu-item-text' href=''>
                        <div *ngIf='(hasStickySideBar && isMenuOpen) || !hasStickySideBar'
                             [@fadeInOut]
                             [class.ml-2]='(hasStickySideBar && isMenuOpen) || !hasStickySideBar'>
                            {{item.title | translate}}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div (click)='sideMenuService.toggleSideMenu(false)'
     *ngIf='isBackDropEnabled'
     [class.fixed-below-header]='isFixedBelowHeader'
     [class.open]='isMenuOpen'
     [class.mobile]='isMobile'
     class='side-menu-backdrop'>
</div>
