import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '../../auth/auth.service';
import { FormCustomValidators } from '../../../utils/form/form-custom-validators.util';
import { BaseComponent } from '../../base-component/base.component';
import { AuthFormsService } from '../auth-forms.service';
import { LoginResponse } from '../classes/LoginResponse';
import { BaseUser } from '../../../../classes/user/BaseUser';
import { GlobalLoaderService } from '../../global-loader/services/global-loader.service';

@UntilDestroy()
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends BaseComponent {
  loginForm: FormGroup;

  constructor(
    private authFormService: AuthFormsService,
    private loaderService: GlobalLoaderService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    super();
    this.initForm();
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, FormCustomValidators.emailValidator()]],
      password: ['', [Validators.required, Validators.min(3)]],
    });
  }

  login() {
    const loginData = {
      email: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value,
    };

    this.authFormService.login(loginData).subscribe((loginResponse: LoginResponse) => {
      this.auth.setToken(loginResponse.userToken);

      this.auth.loadUser().subscribe(
        (userData: BaseUser) => {
          this.auth.setUser(userData);
          this.auth.redirectToPreviousUrlOrHome();

          setTimeout(() => {
            this.loaderService.hide();
          });
        },
        () => {
          this.auth.logout();
          this.loaderService.hide();
        }
      );
    });
  }
}
