<button
        mat-button
        [ngClass]="buttonClass"
        [type]="type"
        (click)="buttonClick.emit()"
        [disabled]="disabled"
        [color]="color">
    <mat-icon *ngIf="icon">
        {{icon}}
    </mat-icon>
    {{text | translate}}
</button>
