export enum UserRole {
  WebAdmin = 'web_admin',
  WebManager = 'web_manager',
  Admin = 'admin',
  Guest = 'guest',
  Unauthorized = 'Unauthorized',
}

export class UserRoleObject {
  id: number;
  roleSlug: UserRole;
  roleTitle: string;
}
