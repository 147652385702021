/** Main constants should store here */
export class AppConstants {
  static readonly LOADER_TIMEOUT = 500;
}

export const ROUTER_TRANSITION_TIMING = '.5s cubic-bezier(0.77, 0, 0.175, 1)';

export enum PageName {
  Home = 'Home',
  Users = 'Users',
}
