import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { FormInputBaseComponent } from './form-input-base.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ButtonComponent } from './button/button.component';
import { SelectComponent } from './select/select.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { ControlValueChangeListenerDirective } from './control-value-change-listener.directive';
import { FormFieldWrapperComponent } from './form-field-wrapper/form-field-wrapper.component';
import { TextareaFieldComponent } from './textarea-field/textarea-field.component';

@NgModule({
  declarations: [
    TextFieldComponent,
    SelectComponent,
    FormInputBaseComponent,
    CheckboxComponent,
    RadioGroupComponent,
    ButtonComponent,
    DatePickerComponent,
    FormHeaderComponent,
    ControlValueChangeListenerDirective,
    FormFieldWrapperComponent,
    TextareaFieldComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    TranslateModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
  ],
  exports: [
    TextFieldComponent,
    SelectComponent,
    CheckboxComponent,
    RadioGroupComponent,
    ButtonComponent,
    DatePickerComponent,
    FormHeaderComponent,
    FormFieldWrapperComponent,
    TextareaFieldComponent,
  ],
})
export class FormComponentsModule {}
