import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthUserComponent } from './auth-user.component';
import { SharedModule } from '../../shared/shared.module';
import { AuthUserRoutingModule } from './auth-user-routing.module';
import { AuthFormsModule } from '../../shared/modules/auth-forms/auth-forms.module';
import { FormComponentsModule } from '../../shared/modules/form-components/form-components.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AuthUserRoutingModule,
    AuthFormsModule,
    FormComponentsModule,
  ],
  declarations: [AuthUserComponent],
})
export class AuthUserModule {}
