import { UserRole, UserRoleObject } from './UserRole';

export class BaseUser {
  constructor(
    public id: string,
    public email: string,
    public role: UserRole,
    public roles: UserRoleObject[]
  ) {}
}
