import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormInputBaseComponent } from '../form-input-base.component';

@UntilDestroy()
@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent extends FormInputBaseComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() autocomplete: string;
  @Input() placeholder: string;
  @Input() type: 'text' | 'email' | 'password' | 'search' = 'text';
  @Output() keyUp: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    // Retrieve the dependency only from the local injector,
    // not from parent or ancestors.
    @Self()
    // We want to be able to use the component without a form,
    // so we mark the dependency as optional.
    @Optional()
    private ngControl: NgControl
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.control = this.ngControl.control;
    return superCalled;
  }

  get isAutoCompleteOff(): boolean {
    return this.autocomplete === 'off';
  }
}
