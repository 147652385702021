import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private buttonLabel = 'X';
  private panelBaseClass = 'app-snackbar';

  constructor(private matSnackBar: MatSnackBar, private translate: TranslateService) {}

  showError(message: string) {
    this.matSnackBar.open(message, this.buttonLabel, {
      panelClass: `${this.panelBaseClass}-error`,
    });
  }

  showSuccess(message: string, translate = false) {
    if (translate) {
      message = this.translate.instant(message);
    }

    this.matSnackBar.open(message, this.buttonLabel, {
      panelClass: `${this.panelBaseClass}-success`,
    });
  }
}
