<form class='my-3 animate__animated animate__fadeIn' [formGroup]='loginForm'>
    <app-text-field
        [id]="'email'"
        [showErrors]='false'
        [showRequiredMarker]='false'
        [label]="'AuthForms.L.EMAIL'"
        [placeholder]="'AuthForms.PH.EMAIL'"
        formControlName='email'>
    </app-text-field>
    <app-text-field
        [id]="'password'"
        [showErrors]='false'
        [showRequiredMarker]='false'
        [label]="'AuthForms.L.PASSWORD'"
        [placeholder]="'AuthForms.PH.PASSWORD'"
        [type]="'password'"
        formControlName='password'>
    </app-text-field>
    <div class='d-flex justify-content-center'>
        <app-button
            [type]="'submit'"
            [color]="'primary'"
            [buttonClass]='BUTTON_CLASS.Raised'
            [disabled]='!loginForm.valid'
            [text]="'AuthForms.BTN.LOGIN'"
            (buttonClick)='login()'>
        </app-button>
    </div>
</form>
