<mat-form-field [appearance]='appearance'>
    <mat-label [class.mat-error]='!!getErrorMessage()'>
        {{label | translate}}
        <span *ngIf='showRequired' class='mat-form-field-required-marker'>*</span>
    </mat-label>
    <input matInput
           appControlValueChangeListener
           [autocomplete]='autocomplete'
           [type]="isAutoCompleteOff ? 'search' : type"
           [placeholder]='placeholder | translate'
           [value]='value'
           [disabled]='disabled'
           (keyup)='keyUp.emit()'
           (input)="onChange($event.target['value'])"
           (blur)='onTouched()' />
</mat-form-field>
<div class='error-message'>
    {{getErrorMessage() | translate}}
</div>
