import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appControlValueChangeListener]',
})
export class ControlValueChangeListenerDirective implements OnInit {
  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private control: NgControl,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.listenValueChanges();
  }

  private listenValueChanges(): void {
    if (!this.control) {
      console.warn('No form control provided!');
      return;
    }

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (this.shouldResetNativeInput(value)) {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
      }
    });
  }

  private shouldResetNativeInput(value: string): boolean {
    const resetValues = [null, undefined, ''];

    return resetValues.includes(value);
  }
}
