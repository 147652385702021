import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from '../base-component/base.component';
import { FormInputLayout } from './classes/FormInputLayout';

@UntilDestroy()
@Component({
  template: '',
})
export class FormInputBaseComponent extends BaseComponent implements ControlValueAccessor {
  @Input() id: string;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() alwaysShowErrors = false;
  @Input() showErrors = true;
  @Input() showRequiredMarker = true;
  @Input() customError: { error: string; message: string };
  @Input() layout: FormInputLayout = FormInputLayout.Standard;

  onChange: (value: string) => void;
  onTouched: () => void;
  value: any;
  control: AbstractControl | FormControl;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  getErrorMessage(): string {
    if (this.showErrors) {
      const errorPrefix = 'FormComponents.Error.';
      let errorSuffix = '';
      if (this.control.pristine && !this.alwaysShowErrors) {
        return errorSuffix;
      }
      if (this.customError) {
        errorSuffix = this.handleCustomError();
      } else if (this.control.hasError('required')) {
        errorSuffix = 'required';
      } else {
        errorSuffix = this.control.errors ? 'value-invalid' : '';
      }
      return errorSuffix ? errorPrefix + errorSuffix : errorSuffix;
    }

    return null;
  }

  private handleCustomError(): string {
    return this.control.hasError(this.customError.error) ? this.customError.message : '';
  }

  get isRequired() {
    const validator = this.control.validator({} as AbstractControl);
    return validator.required;
  }

  get showRequired() {
    return (
      this.showRequiredMarker && this.appearance !== FormInputLayout.Outline && this.isRequired
    );
  }

  get appearance() {
    return this.layout;
  }
}
