import { HttpClient } from '@angular/common/http';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

class HttpLoaderResource implements ITranslationResource {
  suffix = '.json';
  prefix: string;

  constructor(location) {
    this.prefix = `./assets/i18n/${location}/`;
  }
}

/** get translate files */
export const HttpMultiLoaderFactory = (http: HttpClient) => {
  return new MultiTranslateHttpLoader(http, [
    new HttpLoaderResource('auth-forms'),
    new HttpLoaderResource('common'),
    new HttpLoaderResource('file-upload'),
    new HttpLoaderResource('form-components'),
    new HttpLoaderResource('response'),
    new HttpLoaderResource('patient-register'),
    new HttpLoaderResource('articles'),
  ]);
};
