export class AppConfig {
  static readonly LANGUAGES = ['hu', 'en'];
  static readonly DEFAULT_LANGUAGE = AppConfig.LANGUAGES[0];

  static readonly LOGIN_URL = '/login';
  static readonly HOME_URL = '/register-patient-user';

  static readonly TOKEN_STORAGE_KEY = 'auth-user-token';

  static readonly DATE_FORMAT = 'YYYY-MM-D';
  static readonly TIME_FORMAT = 'hh:mm:ss';

  // TODO: should get this from BE on every app load,
  // or MUST BE SURE on Backend side that timeZone will not change ever
  static readonly BACKEND_TIMEZONE = '+00:00';

  static readonly BROWSER_NOT_SUPPORTED = {
    ie11: false,
    ie10: true,
    edge: false,
    safari: false,
  };
}
