export class SideMenuItem {
  constructor(
    public title: string,
    public isShown: boolean,
    public icon: string,
    public url: string,
    public onClick = () => {} // NOSONAR
  ) {
    if (!icon) {
      this.icon = '';
    }
  }
}
