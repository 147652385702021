import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { UserHasRoleDirective } from './directives/user-has-role.directive';
import { UserHasPermissionDirective } from './directives/user-has-permission.directive';

@NgModule({
  imports: [],
  declarations: [UserHasRoleDirective, UserHasPermissionDirective],
  providers: [AuthGuard],
  exports: [UserHasRoleDirective, UserHasPermissionDirective],
})
export class AuthModule {}
