import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { DeviceService } from '../../services/device.service';

@Injectable({
  providedIn: 'root',
})
export class MatModalService {
  constructor(public dialog: MatDialog, private deviceService: DeviceService) {}

  openDialog(
    modalComponent,
    customModalConfig: MatDialogConfig = null,
    disableMobileClass = false
  ): Observable<any> {
    const curConfig = customModalConfig || modalComponent.modalConfig;

    const dialogRef = this.dialog.open(modalComponent, curConfig);

    if (!disableMobileClass) {
      this.addMobileClassToConfig(dialogRef);
    }

    return dialogRef.afterClosed().pipe(first());
  }

  addMobileClassToConfig(dialogRef: MatDialogRef<any>) {
    // add mobile class
    dialogRef.addPanelClass(this.deviceService.isMobile ? 'mobile' : '');
  }
}
